import { AxiosResponse } from "axios";

import { Phase } from "../models/Form/BaseForm";
import API from "../utils/api";

/**
 * Downloads the form metrics for a given cycle and phase.
 * 
 * @param {string} cycleId - The ID of the cycle for which to download the metrics.
 * @param {Phase} phase - The phase of the form to download.
 * @param {string} managerId - The ID of the manager for which to download the metrics. 
 * @returns {Promise<Blob>} A promise that resolves to a Blob containing the downloaded file.
 */
export const downloadFormMetrics = async (
  cycleId: string,
  phase: Phase,
  managerId: string
): Promise<Blob> => {
  const url = "/downloads/forms";

  try {
    return await API.post(
      url,
      { cycle: cycleId, phase, manager: managerId },
      {
        responseType: "blob"
      }
    ).then((response: AxiosResponse) => {
      const file = response.data;

      return file;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};


/**
 * Downloads the performance review scores for a given cycle.
 * 
 * @param {string} cycleId - The ID of the cycle for which to download the scores.
 * @returns {Promise<Blob>} A promise that resolves to a Blob containing the downloaded file.
 */
export const downloadPerformanceReviewScores = async (
  cycleId: string
): Promise<Blob> => {
  const url = "/downloads/forms-scores";

  try {
    return await API.post(
      url,
      { cycle: cycleId },
      {
        responseType: "blob"
      }
    ).then((response: AxiosResponse) => {
      const file = response.data;

      return file;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};
