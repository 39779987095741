import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { useCourses } from "../../hooks/integrations/riseUp/useCourses";
import { RiseUpCourse } from "../../models/integrations/RiseUp/RiseUpCourse";
import { classNames } from "../../utils/styles";

type OwnProps = {
  defaultValue?: RiseUpCourse;
  exclude?: string[];
  onSelectItem?: (item: RiseUpCourse) => void;
};

const RiseUpCourseSelector: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { defaultValue = null, exclude = [], onSelectItem } = props;

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState<RiseUpCourse | null>(defaultValue);

  const { isLoading, data: courses = [] } = useCourses();

  // Create a Set from the exclude array for faster lookups
  const useExcludeSet = (exclude: string[]) =>
    useMemo(() => new Set(exclude), [exclude]);

  // Create a search index
  const useSearchIndex = (courses: RiseUpCourse[]) =>
    useMemo(() => {
      const index = new Map<string, Set<RiseUpCourse>>();
      courses.forEach((course) => {
        // Create all possible substrings (minimum 2 characters)
        const title = course.title.toLowerCase();
        for (let i = 0; i < title.length - 1; i++) {
          for (let j = i + 2; j <= title.length; j++) {
            const substring = title.slice(i, j);
            if (!index.has(substring)) {
              index.set(substring, new Set());
            }
            index.get(substring)!.add(course);
          }
        }
      });
      return index;
    }, [courses]);

  // Main filtering function
  const useFilteredCourses = (
    courses: RiseUpCourse[],
    exclude: string[],
    query: string
  ) => {
    const excludeSet = useExcludeSet(exclude);
    const searchIndex = useSearchIndex(courses);

    const filterByExclude = useCallback(
      (course: RiseUpCourse) => !excludeSet.has(course._id),
      [excludeSet]
    );

    return useMemo(() => {
      const filtered = courses.filter(filterByExclude);

      if (query.trim() === "") {
        return filtered;
      }

      const searchTerms = query
        .toLowerCase()
        .split(/\s+/)
        .filter((term) => term.length >= 2);
      if (searchTerms.length === 0) {
        return filtered;
      }

      const resultSets = searchTerms.map((term) => {
        const matches = new Set<RiseUpCourse>();
        // Find all matching substrings in the index
        searchIndex.forEach((courses, indexedString) => {
          if (indexedString.includes(term)) {
            courses.forEach((course) => {
              if (filterByExclude(course)) {
                matches.add(course);
              }
            });
          }
        });
        return matches;
      });

      // Intersection of all result sets (courses that match all search terms)
      const finalResults = Array.from(
        resultSets.reduce((acc, current) => {
          if (acc.size === 0) return current;
          return new Set(Array.from(acc).filter((x) => current.has(x)));
        })
      );

      return finalResults;
    }, [query, courses, filterByExclude, searchIndex]);
  };

  const filteredList = useFilteredCourses(courses, exclude, query);

  const onChangeHandler = (item: RiseUpCourse) => {
    setSelected(item);

    if (onSelectItem) onSelectItem(item);
  };

  return (
    <Combobox as="div" value={selected} onChange={onChangeHandler}>
      <div className="relative">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: RiseUpCourse) =>
            selected != null
              ? selected?.title
              : t("common.selectors.courses.title")
          }
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredList.length > 0 && (
          <ComboboxOptions className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredList.map((course) => (
              <ComboboxOption
                key={course._id}
                value={course}
                className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                {({ focus, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {course.title} [{course.language}]
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          focus ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
};

export default RiseUpCourseSelector;
