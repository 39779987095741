import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import { useAddMeeting } from "../../hooks/meetings/useAddMeeting";
import { useEditMeeting } from "../../hooks/meetings/useEditMeeting";
import { Employee } from "../../models/Employee/Employee";
import { Phase } from "../../models/Form/BaseForm";
import { FormMeeting, MeetingType } from "../../models/Form/FormMeeting";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { formatISODateStr, parseISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InfoBanner from "../banners/InfoBanner";
import WarningBanner from "../banners/WarningBanner";
import SingleDatePicker from "../dates/SingleDatePicker";
import InputRichText from "../forms/InputRichText";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import MeetingTypeSelector from "../selectors/MeetingTypeSelector";
import TimeSelector from "../selectors/TimeSelector";
import MeetingParticipants from "./MeetingParticipants";

const mapDispatchToProps = {
  notify: showNotification
};

interface OwnProps {
  action: "add" | "edit";
  documentId: string;
  phase: Phase;
  meeting?: FormMeeting;
  defaultParticipant: Partial<Employee>;
  additionalParticipants: Partial<Employee>[];
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const MeetingDateModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "meetings.modal"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    action,
    documentId,
    phase,
    meeting,
    defaultParticipant,
    additionalParticipants,
    notify,
    closeModal
  } = props;
  const [meetingDate, setMeetingDate] = useState<Date | null>(
    meeting?.meetingDate != null
      ? parseISODateStr(meeting.meetingDate)
      : new Date()
  );
  const [meetingType, setMeetingType] = useState<MeetingType | null>(null);
  const [participants, setParticipants] = useState<Partial<Employee>[]>(
    (meeting?.participants as Partial<Employee>[]) ?? [defaultParticipant]
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const addMutation = useAddMeeting(documentId);
  const updateMutation = useEditMeeting(documentId, meeting?._id as string);

  // Component state
  const initialState = {
    body: meeting?.body ?? ""
  };

  const validationSchema = Yup.object({
    body: Yup.string().max(255, "Must be 255 characters or less")
  });

  const footer = meetingDate ? (
    <p>
      {t("selection", {
        date: formatISODateStr(meetingDate.toISOString(), "MMMM do, yyyy")
      })}
    </p>
  ) : undefined;

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if (meetingDate == null) {
            setErrorMessage(t("messages.noDate"));
            return;
          }

          if (participants.length === 0) {
            setErrorMessage(t("messages.noParticipants"));
            return;
          }

          // Mutate the meeting
          await (action === "add"
            ? addMutation.mutate({
                meetingDate,
                meetingType: meetingType ?? MeetingType.ONE_ON_ONE,
                participants: participants.map(
                  (participant) => participant._id as string
                ),
                body: values.body
              })
            : updateMutation.mutate({
                meetingDate,
                meetingType: meetingType ?? MeetingType.ONE_ON_ONE,
                participants: participants.map(
                  (participant) => participant._id as string
                ),
                body: values.body
              }));

          // Notify
          notify({
            type: NotificationType.SUCCESS,
            title: tCommon("notifications.setDiscussionDateSuccess"),
            message: t("messages.success")
          });

          closeModal();

          // Reset form
          actions.resetForm({ values: initialState });
          queryClient.invalidateQueries({
            queryKey: ["activities", documentId]
          });
          queryClient.invalidateQueries({
            queryKey: ["meetings", documentId]
          });
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12 w-full">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("title")}
                  </h2>
                  <p className="mt-1 mb-3 text-sm leading-6 text-gray-600">
                    {t("subtitle")}
                  </p>
                  <InfoBanner message={t("warning")} />
                  {action === "edit" && (
                    <div className="mt-1">
                      <WarningBanner message={t("update.warning")} />
                    </div>
                  )}
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-4 col-start-2">
                      <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                        {t("fields.meetingType")}
                      </label>
                      <MeetingTypeSelector
                        defaultType={meeting?.meetingType}
                        phase={phase}
                        onChange={setMeetingType}
                      />
                    </div>
                    <div className="col-span-4 col-start-2">
                      <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                        {t("fields.participants")}
                      </label>
                      <MeetingParticipants
                        options={[
                          defaultParticipant,
                          ...additionalParticipants
                        ]}
                        selected={participants}
                        onChange={(participants) => {
                          setParticipants(participants);
                        }}
                        disabled={action === "edit"}
                      />
                    </div>
                    <div className="col-span-full">
                      <div className="flex justify-center">
                        <SingleDatePicker
                          defaultDate={meetingDate ?? undefined}
                          onSelectDate={(date) => {
                            const hour =
                              meetingDate?.getHours().toString() ?? "00";
                            const minute = "00";
                            date.setHours(parseInt(hour, 10));
                            date.setMinutes(parseInt(minute, 10));
                            setMeetingDate(date);
                          }}
                          footer={footer}
                        />
                      </div>
                    </div>
                    <div className="col-span-2 col-start-3">
                      <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                        {t("fields.meetingTime")}
                      </label>
                      <TimeSelector
                        defaultHour={meetingDate
                          ?.getHours()
                          .toString()
                          .padStart(2, "0")}
                        defaultMinute={meetingDate
                          ?.getMinutes()
                          .toString()
                          .padStart(2, "0")}
                        onChange={(hour, minute) => {
                          if (meetingDate == null) return;

                          const newDate = new Date(meetingDate);
                          newDate.setHours(parseInt(hour, 10));
                          newDate.setMinutes(parseInt(minute, 10));
                          setMeetingDate(newDate);
                        }}
                      />
                    </div>
                    <div className="col-span-full">
                      <InputRichText
                        innerRef={editorRef}
                        label={t("fields.body")}
                        name="body"
                        helperText={t("helpers.body")}
                        initialValue={initialState.body}
                        onChange={(value: string) => {
                          setFieldValue("body", value);
                        }}
                      />
                    </div>
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isSubmitting ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="small" />}
                {tCommon("actions.save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(MeetingDateModal);
