import { FC, ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import SubSectionHeader from "../../components/headings/SubSectionHeader";
import TeamlistTable from "../../components/teamlist/TeamlistTable";
import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingCancelForm,
  selectCancelFormError,
  selectCancelFormStatus
} from "../../redux/currentTeamlist/selectors";
import {
  cancelForm,
  clearCancelFormStatus
} from "../../redux/currentTeamlist/slice";
import { selectCurrentUser } from "../../redux/currentUser/selectors";
import { prStatusLinks } from "../../utils/links";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isLoadingCancelForm: isLoadingCancelForm,
  cancelStatus: selectCancelFormStatus,
  cancelError: selectCancelFormError
});

const mapDispatchToProps = {
  cancelForm,
  clearStatus: clearCancelFormStatus,
  notify: showNotification
};

type OwnProps = ReduxProps & {
  employee: Employee | undefined;
  documents: BaseForm[];
};

const ReviewTeamlist: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documents,
    currentUser,
    employee,
    cancelStatus,
    cancelError,
    isLoadingCancelForm,
    cancelForm,
    clearStatus,
    notify
  } = props;

  useEffect(() => {
    if (isLoadingCancelForm) return;

    if (cancelError) {
      notify({
        type: NotificationType.ERROR,
        title: tCommon("notifications.cancelFormError"),
        message: cancelError
      });
    }

    if (cancelStatus === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.cancelFormSuccess"),
        message: t("validation.messages.success")
      });

      // Cleanup
      clearStatus();
    }
  }, [
    isLoadingCancelForm,
    cancelStatus,
    cancelError,
    clearStatus,
    notify,
    t,
    tCommon
  ]);

  const directReports = useMemo(() => {
    return documents?.filter(
      (item) => item.relatedEmployer.identifiant !== 999999
    );
  }, [documents]);

  // Create two arrays of documents:
  // - validatedDocuments: documents that have been validated
  // - nonValidatedDocuments: documents that have not been validated yet
  // scheduled
  const [validatedDocuments, nonValidatedDocuments] = useMemo(() => {
    return directReports?.reduce(
      (acc: [BaseForm[], BaseForm[]], item: BaseForm) => {
        if (item.relatedState.ukey === 3) {
          acc[0].push(item); // validatedDocuments
        } else {
          acc[1].push(item); // nonValidatedDocuments
        }
        return acc;
      },
      [[], []]
    );
  }, [directReports]);

  return (
    <div>
      {/* My direct reports */}
      <div className="border-t border-white/10">
        <div className="pt-4 px-4 sm:px-6 lg:px-8">
          <SubSectionHeader
            title={t("teamlist.nonValidated", {
              count: nonValidatedDocuments?.length ?? 0
            })}
            link={prStatusLinks?.[employee?.lang ?? "fr"]}
            linkLabel={t("myPerformance.infoLinkLabel")}
          />
        </div>
        <div className="mb-6 px-4 sm:px-6 lg:px-8">
          <TeamlistTable teamlist={nonValidatedDocuments} />
        </div>
      </div>

      {/* Validated documents */}
      {validatedDocuments?.length ? (
        <div className="border-t border-white/10 pt-11 mb-6">
          <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
            {t("teamlist.validated", {
              count: validatedDocuments?.length ?? 0
            })}
          </h2>
          <div className="mt-6 px-4 sm:px-6 lg:px-8">
            <TeamlistTable
              teamlist={validatedDocuments}
              cancelForm={
                currentUser?.isAdmin
                  ? (formUUID: string) => cancelForm({ formUUID })
                  : undefined
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(ReviewTeamlist);
