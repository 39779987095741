import {
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  Italic,
  Link,
  List,
  Mention,
  Paragraph,
  Strikethrough
} from "ckeditor5";
import { FC, MutableRefObject, ReactElement } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";

type OwnProps = {
  innerRef: MutableRefObject<any>;
  name: string;
  label?: string;
  initialValue?: string | null;
  placeholder?: string;
  isOptional?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

const InputRichText: FC<OwnProps> = (props): ReactElement => {
  const {
    label,
    name,
    disabled,
    isOptional,
    helperText,
    initialValue,
    innerRef,
    onChange
  } = props;

  return (
    <>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}
        {isOptional && (
          <span
            className="text-sm leading-6 text-gray-500"
            id="short-description-optional"
          >
            Optional
          </span>
        )}
      </div>
      <CKEditor
        editor={ClassicEditor}
        config={{
          licenseKey: 'GPL',
          toolbar: {
            items: [
              "bold",
              "italic",
              "strikethrough",
              "|",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote"
            ]
          },
          plugins: [
            Bold,
            Essentials,
            Italic,
            Mention,
            Paragraph,
            BlockQuote,
            List,
            Link,
            Strikethrough
          ],
          initialData: initialValue ?? ""
        }}
        disabled={disabled}
        onReady={(editor) => {
          innerRef.current = editor;
        }}
        onChange={(event, editor) => {
          if (onChange) {
            onChange(editor.getData());
          }
        }}
      />
      {helperText && (
        <p className="mt-2 text-xs text-gray-500" id={`${name}-description`}>
          {helperText}
        </p>
      )}
    </>
  );
};

export default InputRichText;
